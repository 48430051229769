<template>
  <div>
    <form class="form_default">
      <div class="form-group">
        <label  class="req">Nom du type de programme</label>
        <input
          type="text"
          name="libelle"
          v-model='form.libelle'
          class="form-control form-control-lg"
          aria-label=".form-control-lg example"
        >
        <span
          v-if="form.errors().has('libelle')"
          v-text="form.errors().get('libelle')"
          class="errorMsg">
        </span>
      </div>
      <div class="form-group">
        <label for="">Institution</label>
        <select class="form-control" 
                name="" 
                id=""
                v-model="form.entreprise">
          <option value="">--Choisissez une institution--</option>
          <option value="AGEROUTE">AGEROUTE</option>
          <option value="DGIR">DGIR</option>
          <option value="FER">FER</option>
        </select>
      </div>
      <div class="button_form">
        <button
          type="button"
          class="btn btn-f-blue enter_btn"
          @click='submit'>
          <i class="flaticon-floppy-disk"></i> Enregistrer
        </button>
        <button
          type="reset"
          class="btn btn-f-blue cancle_btn ml-4">
          <i class="flaticon-cancel"></i> Annuler
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import form from 'vuejs-form'
import {mapActions,mapGetters,mapMutations} from "vuex"

export default {
  name:"UpdateTypeProgramme",
  props:["id_categorie"],
  data:()=>({
    form:form({
      id:"",
      libelle:"",
      entreprise:"",
    }).rules({
      id:"required",
      libelle:"required"
    }).messages({
      id:"Ce champs est requis!",
      libelle:"Ce champs est requis!"
    })
  }),
  watch:{
    msgSuccessCategorie(){
      if (this.msgSuccessCategorie) {
        this.$emit("updated",{updated:true})
      }
    },
    categorie(){
      if (this.categorie) {
        console.log(this.categorie)
        
        this.form.id=this.categorie.categorie_programme[0].id      
        this.form.libelle=this.categorie.categorie_programme[0].libelle
        this.form.entreprise=this.categorie.categorie_programme[0].entreprise
        this.setCategorie("")
             
      }
    }
  },
  created(){
    this.getCategorie({id:this.id_categorie})
  },
  computed:{
    ...mapGetters(["msgSuccessCategorie","msgFailCategorie","categorie"])
  },
  methods:{
    ...mapActions(["updateCategorie","getCategorie"]),
    ...mapMutations(["setMsgSuccessCategorie","setMsgFailCategorie","setCategorie"]),
    submit(){
      if (!this.form.validate().errors().any()) {
        this.updateCategorie(this.form.data)
      }
    }
  }
}
</script>

<style>
input[type="text"],
label,
select,
button{
  font-size: 0.6em;
}
input{
  height: fit-content;
}
</style>